import { css } from "@emotion/react"
import { Button, InputBase, Modal, SelectField, Typography } from "matsuri-ui"
import {
    Fragment,
    Reducer,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from "react"
import {
    ScopeAction,
    ScopeResource,
    UserScope,
    availableScopeActions,
    availableScopeResources,
    castToScopeActions,
    castToScopeResources,
} from "../hooks/useUsers"

const scopeReducer: Reducer<
    UserScope,
    | { type: "add"; resource: ScopeResource }
    | { type: "delete"; resource: ScopeResource }
    | {
          type: "update"
          resource: ScopeResource
          value: ScopeAction
      }
    | { type: "set"; value: UserScope }
> = (state, action) => {
    switch (action.type) {
        case "add":
            return action.resource in state
                ? state
                : { ...state, [action.resource]: "none" }
        case "delete":
            return Object.fromEntries(
                Object.entries(state).filter(([key]) => key !== action.resource)
            )
        case "update":
            return action.resource in state
                ? { ...state, [action.resource]: action.value }
                : state
        case "set":
            return action.value
        default:
            throw new Error("unreachable")
    }
}

export const ScopeModal: React.FC<{
    open?: boolean
    onClose: () => void
    onClickOutside?: () => void
    onSubmit: (scope: UserScope) => void
    defaultScope?: UserScope
}> = ({ defaultScope, onSubmit, ...props }) => {
    const [state, dispatch] = useReducer(scopeReducer, {})
    useEffect(() => {
        if (defaultScope) {
            dispatch({ type: "set", value: defaultScope })
        }
    }, [defaultScope])

    const handleDelete = useCallback(
        ({ currentTarget }: React.SyntheticEvent) => {
            const attr = currentTarget.getAttribute("data-resource")
            const resource = attr ? castToScopeResources(attr) : undefined

            if (resource) {
                dispatch({ type: "delete", resource })
            }
        },
        []
    )

    const handleUpdate = useCallback(
        (key: string) => (nextValue?: string) => {
            const resource = castToScopeResources(key)
            const value = nextValue && castToScopeActions(nextValue)

            if (resource && value) {
                dispatch({ type: "update", resource, value })
            }
        },
        []
    )

    const [selectedResource, setSelectedResource] = useState<string>()
    const handleSelectResource = useCallback(
        (value?: string) => setSelectedResource(value),
        []
    )

    const handleAdd = useCallback(() => {
        const resource = selectedResource
            ? castToScopeResources(selectedResource)
            : undefined

        if (resource) {
            dispatch({ type: "add", resource })
        }
    }, [selectedResource])

    const handleSubmit = useCallback(() => {
        onSubmit(Object.keys(state).length === 0 ? { listings: "none" } : state)
    }, [onSubmit, state])

    return (
        <Modal
            backdrop
            header={<Typography variant="h3">Scope</Typography>}
            body={
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    `}
                >
                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 8px;
                        `}
                    >
                        {Object.keys(state).map((key) => (
                            <Fragment key={key}>
                                <InputBase
                                    readOnly
                                    defaultValue={key}
                                    style={{ minWidth: 200 }}
                                />
                                <SelectField
                                    defaultValue={state[key]}
                                    options={availableScopeActions.map(
                                        (action) => ({
                                            value: action,
                                            label: action,
                                        })
                                    )}
                                    style={{ minWidth: 150 }}
                                    onChange={handleUpdate(key)}
                                />
                                <Button
                                    color="error"
                                    data-resource={key}
                                    onClick={handleDelete}
                                >
                                    削除
                                </Button>
                            </Fragment>
                        ))}
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-wrap: nowrap;
                            gap: 8px;
                        `}
                    >
                        <SelectField
                            options={availableScopeResources
                                .filter((key) => !(key in state))
                                .map((value) => ({
                                    value,
                                    label: value,
                                }))}
                            style={{ minWidth: 200 }}
                            // defaultValueに適当な値を設定しておかないと、選択時に何も表示されない？
                            // 実際にはlistingsが選択肢にない場合があるのでここにlistingsを設定するのは特に意味がない
                            defaultValue="listings"
                            onChange={handleSelectResource}
                        />
                        <Button color="primary" onClick={handleAdd}>
                            追加
                        </Button>
                    </div>
                </div>
            }
            footer={
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="filled"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        保存する
                    </Button>
                </div>
            }
            style={{ width: 600 }}
            {...props}
        />
    )
}

export const ScopeSelectField = ({
    onChange,
    value,
}: {
    onChange: (scope: UserScope) => void
    value: UserScope
}) => {
    return (
        <div
            css={css`
                display: grid;
            `}
        >
            <Typography variant="caption">Scope</Typography>

            <div
                css={css`
                    display: grid;
                    gap: 8px;
                `}
            >
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 8px;
                    `}
                >
                    {availableScopeResources.map((key) => (
                        <Fragment key={key}>
                            <InputBase
                                readOnly
                                defaultValue={key}
                                style={{ minWidth: 200 }}
                            />
                            <SelectField
                                value={value[key as ScopeResource] || "none"}
                                options={availableScopeActions.map(
                                    (action) => ({
                                        value: action,
                                        label: action,
                                    })
                                )}
                                style={{ minWidth: 150 }}
                                onChange={(nextValue?: string) => {
                                    if (!nextValue) {
                                        return
                                    }

                                    const resource = castToScopeResources(key)
                                    if (!resource) {
                                        return
                                    }

                                    const action = castToScopeActions(nextValue)
                                    if (!action) {
                                        return
                                    }

                                    onChange({
                                        ...value,
                                        [resource]: action,
                                    })
                                }}
                            />
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}
