import { useState } from "react"

export interface UseMenuState {
    anchorEl: HTMLElement | null
    open: boolean
    onClickOutside?: () => void
    onClose: () => void
    handleOpenMenu: (event: React.SyntheticEvent<HTMLElement>) => void
}

export const useMenu = (options: { onClickOutside: boolean }): UseMenuState => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    return {
        anchorEl,
        open: Boolean(anchorEl),
        onClickOutside: options.onClickOutside
            ? () => setAnchorEl(null)
            : undefined,
        onClose: () => setAnchorEl(null),
        handleOpenMenu: (event: React.SyntheticEvent<HTMLElement>) =>
            setAnchorEl(event.currentTarget),
    }
}
