import {
    Button,
    FieldGroup,
    Modal,
    SelectField,
    TextField,
    Typography,
    useAlert,
} from "matsuri-ui"
import { PageHeader } from "../components/PageHeader/PageHeader"
import { ScopeModal } from "../parts/ScopeModal"
import { UserAuthorityChips } from "../parts/UserAuthorityChips"
import {
    UserScope,
    displayActivationStatus,
    displayAuthority,
    requestSetScope,
    useUser,
    requestUpdateAuthority,
    AuthorityUpdatable,
    authorityUpdatable,
} from "../hooks/useUsers"
import { css } from "@emotion/react"
import { useAuthGuardCtx } from "../hooks/useAuthGuard"
import { FormEvent, useCallback, useState } from "react"
import { useMenu } from "../components/useMenu"
import { useParams } from "react-router-dom"
import { assertIsDefined } from "../helpers/assertIsDefined"

const textWithButtonStyle = css`
    display: grid;
    grid-template-columns: 1fr 168px;
    gap: 8px;
    align-items: flex-end;
`

export const UserDetailPage = () => {
    const { id: userId } = useParams<{ id: string }>()
    assertIsDefined(userId)

    const { token } = useAuthGuardCtx()
    const { data: user, refetch } = useUser(token, userId)

    const { addAlert, throwAlert } = useAlert()

    const { handleOpenMenu, ...menuProps } = useMenu({ onClickOutside: false })
    const [open, setOpen] = useState(false)

    const handleUpdateScope = useCallback(
        async (scope: UserScope) => {
            const { error } = await requestSetScope(token, {
                userId,
                scope,
            })

            if (error) {
                addAlert(`エラーが発生しました: ${JSON.stringify(error)}`, {
                    severity: "error",
                })

                return
            } else {
                addAlert(`スコープを更新しました`, {
                    severity: "success",
                    duration: 3500,
                })
            }

            menuProps.onClose()
            refetch()
        },
        [addAlert, menuProps, refetch, token, userId]
    )

    return (
        <>
            <PageHeader>
                <Typography variant="h2">ユーザー情報</Typography>
            </PageHeader>

            <FieldGroup loading={!user}>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    `}
                >
                    {user && <UserAuthorityChips user={user} />}

                    <TextField label="ID" defaultValue={user?.id} readOnly />

                    <TextField
                        label="メールアドレス"
                        defaultValue={user?.email}
                        readOnly
                    />

                    <TextField
                        label="所属会社ID"
                        defaultValue={user?.companyId}
                        readOnly
                    />

                    <TextField
                        label="名前"
                        defaultValue={user?.name}
                        readOnly
                    />

                    <TextField
                        label="有効状態"
                        defaultValue={
                            user
                                ? displayActivationStatus(user.activationStatus)
                                : undefined
                        }
                        readOnly
                    />

                    <div css={textWithButtonStyle}>
                        <TextField
                            key={user?.authority}
                            label="権限"
                            defaultValue={
                                user
                                    ? displayAuthority(user.authority)
                                    : undefined
                            }
                            readOnly
                        />
                        <Button
                            onClick={() => {
                                setOpen(true)
                            }}
                            disabled={
                                !authorityUpdatable.includes(
                                    displayAuthority(
                                        user?.authority ?? 0
                                    ) as AuthorityUpdatable
                                )
                            }
                        >
                            権限を編集
                        </Button>
                    </div>

                    <Modal
                        width={350}
                        maxWidth={350}
                        backdrop
                        open={open}
                        onClose={() => setOpen(false)}
                        header={
                            <Typography variant="h3">権限の編集</Typography>
                        }
                        body={
                            <form
                                id="updateAuthorityForm"
                                onSubmit={async (
                                    event: FormEvent<HTMLFormElement>
                                ) => {
                                    event.preventDefault()

                                    const formData = new FormData(
                                        event.currentTarget
                                    )
                                    const authority = formData.get(
                                        "authority"
                                    ) as AuthorityUpdatable
                                    const { error } =
                                        await requestUpdateAuthority(
                                            token,
                                            userId,
                                            {
                                                authority: authority,
                                            }
                                        )

                                    throwAlert(error, {
                                        errorMessage:
                                            "権限の更新に失敗しました",
                                        successMessage: "権限を更新しました",
                                    })

                                    setOpen(false)
                                    refetch()
                                }}
                            >
                                <SelectField
                                    required
                                    disablePortal
                                    name="authority"
                                    label={"権限の選択"}
                                    options={authorityUpdatable.map((auth) => ({
                                        label: auth,
                                        value: auth,
                                    }))}
                                    defaultValue={displayAuthority(
                                        user?.authority ?? 7
                                    )}
                                />
                            </form>
                        }
                        footer={
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: flex-end;
                                `}
                            >
                                <Button
                                    variant="filled"
                                    color="primary"
                                    type="submit"
                                    form="updateAuthorityForm"
                                >
                                    更新
                                </Button>
                            </div>
                        }
                    />

                    <div css={textWithButtonStyle}>
                        <TextField
                            label="許可されたスコープ"
                            defaultValue={JSON.stringify(user?.scope)}
                            readOnly
                        />
                        <Button onClick={handleOpenMenu}>スコープを編集</Button>
                    </div>
                </div>
            </FieldGroup>

            <ScopeModal
                defaultScope={user?.scope}
                onSubmit={handleUpdateScope}
                {...menuProps}
            />
        </>
    )
}
