import { Button } from "matsuri-ui"
import { useNavigate } from "react-router-dom"

export const CompanyPage = () => {
    const navigate = useNavigate()

    return (
        <Button
            variant="filled"
            color="primary"
            onClick={() => {
                navigate("/companies/new")
            }}
        >
            新規会社を登録
        </Button>
    )
}
