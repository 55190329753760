import { ActivationFinished } from "./ActivationFinished"
import { requestActivateUser } from "../../hooks/useUsers"
import { useCallback, useState } from "react"
import { useQuery } from "../../hooks/useQuery"
import { useNavigate } from "react-router-dom"

export const useUserActivationForm = () => {
    const [fetching, setFetching] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const query = useQuery()
    const activationToken = query.get("activation_token") || ""
    const userId = query.get("user_id") || ""

    const navigate = useNavigate()

    const handleSubmit = useCallback(async () => {
        setFetching(true)
        const { error } = await requestActivateUser(userId, activationToken)
        setFetching(false)

        if (error) {
            setErrorMessage(error.message)
        } else {
            navigate(ActivationFinished.path)
        }
    }, [userId, activationToken, navigate])

    const handleClearError = useCallback(() => {
        setErrorMessage("")
    }, [])

    return {
        fetching,
        handleSubmit,
        handleClearError,
        errorMessage,
    }
}
