import { fetcher, useAuthFetch, useFetch } from "matsuri-hooks"
import { m2mUsers } from "../endpoints/m2m-users"
import { m2mUsers_v1 } from "../endpoints/m2m-users.v1"

export type ScopeResource =
    | "listings"
    | "messages"
    | "cleanings"
    | "reservations"
    | "payments"
    | "monthly"
    | "calendars"

export const availableScopeResources: ScopeResource[] = [
    "listings",
    "messages",
    "cleanings",
    "reservations",
    "payments",
    "monthly",
    "calendars",
]

export const castToScopeResources = (str: string): ScopeResource | undefined =>
    (availableScopeResources as string[]).includes(str)
        ? (str as ScopeResource)
        : undefined

export type ScopeAction = "none" | "read" | "write" | "admin"

export const availableScopeActions: ScopeAction[] = [
    "none",
    "read",
    "write",
    "admin",
]

export const castToScopeActions = (str: string): ScopeAction | undefined =>
    (availableScopeActions as string[]).includes(str)
        ? (str as ScopeAction)
        : undefined

export type UserScope = { [resource: string]: ScopeAction }

export type ActivationStatus =
    | "Unknown"
    | "BeforeVerification"
    | "Active"
    | "Banned"
    | "Deactivated"

export const displayActivationStatus = (status: ActivationStatus) => {
    const map: { [key in ActivationStatus]: string } = {
        Unknown: "不明",
        BeforeVerification: "有効化前",
        Active: "有効",
        Banned: "禁止",
        Deactivated: "無効",
    }

    return map[status]
}

export interface User {
    id: string
    companyId: string
    email: string
    scope: UserScope
    createdAt: string
    updatedAt: string
    authority: number
    name: string
    activationStatus: ActivationStatus
}

export const authorities = [
    "Unauthorized",
    "GeneralUser",
    "Manager",
    "Admin",
    "System",
    "DevAdmin",
] as const
export type Authority = typeof authorities[number]
export const authorityUpdatable = authorities.filter(
    (a): a is "GeneralUser" | "Manager" =>
        a === "GeneralUser" || a === "Manager"
)
export type AuthorityUpdatable = typeof authorityUpdatable[number]

export const hasManagerAuthority = (user: User): boolean => user.authority <= 2

export const hasCleaningCleaningManagerAuthority = (user: User): boolean =>
    hasManagerAuthority(user) &&
    (user.scope["cleanings"] === "write" || user.scope["cleanings"] === "admin")

export const hasCleaningListingManagerAuthority = (user: User): boolean =>
    hasManagerAuthority(user) &&
    hasCleaningCleaningManagerAuthority(user) &&
    (user.scope["listings"] === "write" || user.scope["listings"] === "admin")

export const hasSumycaMonthlyAdmin = (user: User): boolean =>
    hasManagerAuthority(user) && user.scope["monthly"] === "admin"

export const displayAuthority = (authority: number) => {
    switch (authority) {
        case 0:
            return "Unauthorized"
        case 1:
            return "Admin"
        case 2:
            return "Manager"
        case 5:
            return "System"
        case 6:
            return "DevAdmin"
        case 7:
            return "GeneralUser"
        default:
            return `Other (${authority})`
    }
}

export const useUsers = (token: string, activeStatuses: string) => {
    return useAuthFetch<User[]>(
        token,
        m2mUsers.v1.findUsersByCompanyId({
            statuses: activeStatuses,
        }),
        {}
    )
}

export const requestSetScope = (
    token: string,
    input: { userId: string; scope: UserScope }
) => {
    return fetcher<void>(m2mUsers.v1.setAuthorityScope(), {
        method: "PUT",
        token,
        body: JSON.stringify(input),
    })
}

export const requestCreateUser = (
    token: string,
    input: { name: string; email: string; password: string; authority: string }
) => {
    return fetcher<{ id: string }>(m2mUsers.v1.createUser(), {
        method: "POST",
        token: token,
        body: JSON.stringify(input),
    })
}

export const useUser = (token: string, id: string | undefined) => {
    return useFetch<User>(id ? m2mUsers_v1.findUserById({ id }) : null, {
        token,
    })
}

export const requestUpdateUser = (
    token: string,
    id: string,
    input: { name: string }
) => {
    return fetcher(m2mUsers.v1.updateUser({ id }), {
        method: "PUT",
        token,
        body: JSON.stringify(input),
    })
}

export const requestActivateUser = (
    userId: string,
    activationToken: string
) => {
    return fetcher(m2mUsers_v1.activateUser(), {
        method: "POST",
        body: JSON.stringify({
            activationToken,
            userId,
        }),
    })
}

export const requestUpdateAuthority = (
    token: string,
    userId: string,
    input: {
        authority: string
    }
) => {
    return fetcher(m2mUsers_v1.updateAuthority({ id: userId }), {
        method: "PUT",
        body: JSON.stringify(input),
        token,
    })
}

export const requestResetPassword = (
    resetToken: string,
    userId: string,
    newPassword: string
) => {
    return fetcher(m2mUsers_v1.resetPassword(), {
        method: "POST",
        body: JSON.stringify({ resetToken, userId, newPassword }),
    })
}

export const requestSendResetPasswordEmail = (email: string) => {
    return fetcher(m2mUsers_v1.sendResetPasswordEmail(), {
        method: "POST",
        body: JSON.stringify({ email }),
    })
}

export const requestSendActivationEmail = (email: string) => {
    return fetcher(m2mUsers_v1.sendActivationEmail(), {
        method: "POST",
        body: JSON.stringify({ email }),
    })
}

export const requestDeactivateUser = (token: string, id: string) => {
    return fetcher(m2mUsers_v1.deactivateUser({ id }), {
        method: "POST",
        token,
    })
}

export const requestMfaLoginTokenMail = (token: string) => {
    return fetcher(m2mUsers_v1.publishMFACode(), {
        method: "POST",
        token,
    })
}

export const requestLoginWithMfa = (token: string, input: { code: string }) => {
    return fetcher<{ token: { accessToken: string } }>(
        m2mUsers_v1.loginWithMFA(),
        {
            method: "POST",
            token,
            body: JSON.stringify(input),
        }
    )
}
