export const parseJWT = <T extends Record<string, unknown>>(
    jwt: string
): T | undefined => {
    const splitted = jwt.split(".")
    if (splitted.length !== 3) return

    const [, payload] = splitted
    const data = JSON.parse(atob(payload))
    if (!data) return

    return data
}
