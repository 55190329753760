import { css } from "@emotion/react"
import { Button, Modal, TextField, Typography } from "matsuri-ui"
import { useResetPasswordRequest } from "./useResetPasswordRequest"

const sx = {
    root: {
        padding: "1vh 4vw",
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        width: "90%",
    },
    noshrink: {
        flexShrink: 0,
    },
} as const

export const ResetPasswordRequestForm: Page = () => {
    const {
        fetching,
        errorMessage,
        handleSubmit,
        handleClearError,
        email,
        setEmail,
    } = useResetPasswordRequest()

    return (
        <>
            <h2>パスワードの再発行</h2>
            {errorMessage && (
                <Modal
                    style={sx.modal}
                    body={
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 16px;
                            `}
                        >
                            <Typography
                                color="error"
                                variant="h4"
                                align="center"
                            >
                                ERROR
                            </Typography>
                            <Typography>{errorMessage}</Typography>
                        </div>
                    }
                    footer={
                        <Button
                            variant="filled"
                            color="primary"
                            fullWidth
                            onClick={handleClearError}
                        >
                            OK
                        </Button>
                    }
                    backdrop
                />
            )}

            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                `}
            >
                <TextField
                    bottomGutter
                    onChange={setEmail}
                    value={email}
                    type="email"
                    label="パスワードを再発行したいアカウントのEmailをご入力ください"
                />

                <Button onClick={handleSubmit} disabled={fetching}>
                    送信
                </Button>
            </div>
        </>
    )
}

ResetPasswordRequestForm.path = "/reset_password_request_form"
