import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"

// ログイン後、URLに入っているredirect_uriパラめーたーを取得してそこにリダイレクトするやつ
export const useLoginRedirect = () => {
    const navigate = useNavigate()
    const query = useQuery()

    return useCallback(
        (fallbackUri: string) => {
            const redirectUri = decodeURIComponent(
                query.get("redirectUri") ?? ""
            )
            navigate(redirectUri || fallbackUri)
        },
        [navigate, query]
    )
}
