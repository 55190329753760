import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useAuthGuardCtx = () => {
    const { authenticated, token, login, logout } = useAuth()
    const location = useLocation()

    // ログインしてなければログイン画面に飛ばす
    // usersはログインしてなくても使えるページ(パスワードリセットなど)とそうでないページがあるためここでリダイレクト処理をしているが、
    // 本当はrouterの分岐するところでauth guardを張るなどした方がいい
    const navigate = useNavigate()
    useEffect(() => {
        if (!authenticated) {
            navigate(
                `/login?redirectUri=${encodeURIComponent(location.pathname)}`
            )
        }
    }, [authenticated, location.pathname, navigate])

    return {
        authenticated,
        token,
        login,
        logout,
    }
}
