import { Chip, Typography } from "matsuri-ui"
import {
    User,
    hasCleaningCleaningManagerAuthority,
    hasCleaningListingManagerAuthority,
    hasSumycaMonthlyAdmin,
} from "../hooks/useUsers"
import { css } from "@emotion/react"

export const UserAuthorityChips: React.VFC<{ user: User }> = ({ user }) => {
    const chips = [
        hasCleaningListingManagerAuthority(user) && (
            <Typography key="CleaningListingManager">
                <Chip color="primary">物件管理者</Chip> (m2m-cleaning)
            </Typography>
        ),
        hasCleaningCleaningManagerAuthority(user) && (
            <Typography key="CleaningCleaningManager">
                <Chip color="primary">清掃管理者</Chip> (m2m-cleaning)
            </Typography>
        ),
        hasSumycaMonthlyAdmin(user) && (
            <Typography key="SumycaMonthlyAdmin">
                <Chip color="secondary">マンスリー管理者</Chip> (Sumyca)
            </Typography>
        ),
    ].filter(Boolean)

    return chips.length > 0 ? (
        <>
            <Typography>このユーザーは以下の権限を持っています:</Typography>
            <div
                css={css`
                    display: grid;
                    gap: 7px;
                `}
            >
                {chips}
            </div>
        </>
    ) : null
}
