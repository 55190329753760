import { PageHeader } from "../components/PageHeader/PageHeader"
import {
    Typography,
    defaultTheme,
    useAlert,
    resetButtonStyle,
} from "matsuri-ui"
import { css } from "@emotion/react"
import { m2mAuthTokenApi } from "m2m-components/storage/client"
import {
    requestLoginWithMfa,
    requestMfaLoginTokenMail,
} from "../hooks/useUsers"
import { useAuthGuardCtx } from "../hooks/useAuthGuard"
import { useLoginRedirect } from "../parts/useLoginRedirect"
import { useReducer } from "react"
import { useRef } from "react"
import { useState } from "react"

const SixDigitField = ({
    onChange,
}: {
    onChange?: (value: string) => void
}) => {
    const [code, setCode] = useState<string[]>([])
    const ref = useRef<HTMLInputElement>(null)

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => {
                ref.current?.focus()
            }}
            css={css`
                position: relative;
                width: fit-content;

                input:focus + div {
                    display: ${code.length < 6 ? "block" : "none"};
                }
                input:focus + div + div .digit-box {
                    border: 1px solid
                        ${defaultTheme.palette.intentions.primary.main};
                }
            `}
        >
            <input
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                pattern="\d{6}"
                value={code.join("")}
                onChange={(event) => {
                    const codes = event.currentTarget.value
                        .split("")
                        .filter((ch) => ch.match(/[0-9]/))
                        .slice(0, 6)

                    setCode(codes)
                    onChange?.(codes.join(""))
                }}
                ref={ref}
                css={css`
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    overflow: hidden;
                    opacity: 0;
                `}
            />
            <div
                css={css`
                    position: absolute;
                    top: calc((100% - 24px) / 2);
                    left: ${code.length * (48 + 8) + 12}px;
                    display: none;
                    width: 1px;
                    height: 24px;
                    background-color: #000; /* お手製カーソル(今何桁目を入力しているのかわかるように) */
                `}
            />
            <div
                css={css`
                    display: flex;
                    gap: 8px;
                    width: fit-content;
                `}
            >
                {Array.from({ length: 6 }).map((_, i) => (
                    <div
                        className="digit-box"
                        key={i}
                        css={css`
                            display: flex;
                            justify-content: center;
                            width: 48px;
                            height: 48px;
                            border: 1px solid ${defaultTheme.palette.gray[500]};
                            border-radius: 3px;
                        `}
                    >
                        <span
                            css={css`
                                font-size: 32px;
                            `}
                        >
                            {code[i] || " "}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const MFALogin = () => {
    const { token } = useAuthGuardCtx()
    const { throwAlert } = useAlert()

    const [renderKey, forceRerender] = useReducer((p) => !p, false)
    const loginRedirect = useLoginRedirect()

    return (
        <>
            <PageHeader>
                <Typography variant="h2">
                    MFAによる追加認証のステップがあります。
                </Typography>
            </PageHeader>

            <div
                css={css`
                    display: grid;
                    gap: 16px;
                `}
            >
                <Typography>
                    登録しているメールアドレスに、MFA認証のメールを送信しました。そちらに送られた6桁の数字を入力してください。
                    <button
                        type="button"
                        css={[
                            resetButtonStyle,
                            css`
                                text-decoration: underline;
                            `,
                        ]}
                        onClick={async () => {
                            const { error } = await requestMfaLoginTokenMail(
                                token
                            )

                            throwAlert(error, {
                                successMessage: "メールを再送しました。",
                                errorMessage:
                                    "メールの送信に失敗しました。しばらく時間を置いてから再度お試しください。",
                            })
                        }}
                    >
                        メールを再送する
                    </button>
                </Typography>

                <SixDigitField
                    key={`${renderKey}`}
                    onChange={async (code) => {
                        if (code.length === 6) {
                            const { data, error } = await requestLoginWithMfa(
                                token,
                                {
                                    code,
                                }
                            )
                            const authToken = data?.token.accessToken

                            throwAlert(error, {
                                successMessage: "MFAトークンを確認しました",
                                errorMessage:
                                    "認証に失敗しました。コードを再度確認してください。",
                                onSuccess: () => {
                                    if (authToken) {
                                        m2mAuthTokenApi.set(authToken)
                                        loginRedirect("/users")
                                    }
                                },
                                onError: () => {
                                    forceRerender()
                                },
                            })
                        }
                    }}
                />
            </div>
        </>
    )
}
