import { fetcher } from "matsuri-hooks"
import { m2mUsers_v1 } from "../endpoints/m2m-users.v1"
import { UserScope } from "./useUsers"

export const requestCreateCompany = (
    token: string,
    input: {
        name: string
        email: string
        password: string
        userName: string
        userEmail: string
        userAuthority: string
        scope: UserScope
    }
) => {
    return fetcher(m2mUsers_v1.createCompany(), {
        token,
        method: "POST",
        body: JSON.stringify(input),
    })
}
