import { Button, TextField, Typography, useAlert } from "matsuri-ui"
import { PageHeader } from "../components/PageHeader/PageHeader"
import { getAuthority } from "../parts/useAuthPayload"
import { m2mAuthTokenApi } from "m2m-components/storage/client"
import { requestMfaLoginTokenMail } from "../hooks/useUsers"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useLoginRedirect } from "../parts/useLoginRedirect"
import { useQuery } from "../hooks/useQuery"
import React from "react"
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom"
import { getDomains } from "m2m-components/domains"

const domains = getDomains()

export const Login: React.FC = () => {
    const navigate = useNavigate()
    const { login } = useAuth()
    const query = useQuery()
    const { throwAlert } = useAlert()
    const loginRedirect = useLoginRedirect()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const input = Object.fromEntries(new FormData(event.currentTarget))
        const { error } = await login({
            email: input["email"] as string,
            password: input["password"] as string,
        })
        throwAlert(error, {
            ignoreSuccess: true,
            errorMessage: "ログインに失敗しました",
            onSuccess: async () => {
                // トークンを同期的に取りたいため
                const token = (await m2mAuthTokenApi.getCache()) ?? ""
                const authority = getAuthority(token)

                if (authority !== "Admin") {
                    loginRedirect("/")
                } else {
                    await requestMfaLoginTokenMail(token)

                    navigate(
                        `/mfa_login?redirectUri=${encodeURIComponent(
                            query.get("redirectUri") ?? ""
                        )}`
                    )
                }
            },
        })
    }

    return (
        <>
            <PageHeader>
                <Typography variant="h2">ログイン</Typography>
            </PageHeader>

            <form
                onSubmit={handleSubmit}
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 48px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    `}
                >
                    <TextField
                        name="email"
                        label="メールアドレス"
                        type="email"
                        autoComplete="email"
                    />
                    <TextField
                        name="password"
                        label="パスワード"
                        type="password"
                        autoComplete="current-password"
                    />
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <Button
                        as="a"
                        target="_blank"
                        href={`${domains.users}/reset_password_request_form`}
                        rel="noopener noreferrer"
                    >
                        パスワードを忘れた場合はこちら
                    </Button>
                    <Button variant="filled" color="primary">
                        ログイン
                    </Button>
                </div>
            </form>
        </>
    )
}
