import "m2m-components/m2m-apps-menu"
import "m2m-components/m2m-releases-link"
import "m2m-components/m2m-user-menu"
import { ActivationFinished } from "./pages/ActivationForm/ActivationFinished"
import { ActivationForm } from "./pages/ActivationForm/ActivationForm"
import { AlertProvider, Chip, GlobalMenu, ModalProvider } from "matsuri-ui"
import { CompanyPage } from "./pages/CompanyPage"
import { FeedbackProvider } from "m2m-components/feedback"
import { ListUsers } from "./pages/ListUsers"
import { Login } from "./pages/Login"
import {
    M2mAuthenticationProvider,
    useAuth,
} from "m2m-components/storage/useM2mAuth"
import { MFALogin } from "./pages/MFALogin"
import { MyPage } from "./pages/MyPage"
import {
    NavLink,
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom"
import { NewCompanyPage } from "./pages/NewCompanyPage"
import { ResetPasswordFinished } from "./pages/ResetPasswordForm/ResetPasswordFinished"
import { ResetPasswordForm } from "./pages/ResetPasswordForm/ResetPasswordForm"
import { ResetPasswordRequestFinished } from "./pages/ResetPasswordRequestForm/ResetPasswordRequestFinished"
import { ResetPasswordRequestForm } from "./pages/ResetPasswordRequestForm/ResetPasswordRequestForm"
import { UserDetailPage } from "./pages/UserDetailPage"
import { css } from "@emotion/react"
import { initM2mComponents } from "m2m-components/initM2mComponents"
import { parseJWT } from "./helpers/parseJWT"
import { useAuthPayload } from "./parts/useAuthPayload"
import React from "react"

initM2mComponents({
    env: process.env.NODE_ENV === "production" ? "production" : "development",
})

const AppContent = () => {
    const { authenticated } = useAuth()
    const payload = useAuthPayload()
    return (
        <Router basename={process.env.BASENAME ?? ""}>
            <GlobalMenu
                logo={
                    <img
                        css={css`
                            display: flex;
                        `}
                        width="52"
                        height="52"
                        src="/logo.png"
                        alt="m2m-users"
                    />
                }
                headerItems={
                    <React.Fragment>
                        {payload?.loginType === "MFA" && (
                            <Chip color="primary">MFAログイン</Chip>
                        )}
                        {authenticated && (
                            <m2m-releases-link product="m2m-users" />
                        )}
                        <m2m-apps-menu />
                        <m2m-user-menu />
                    </React.Fragment>
                }
                items={
                    authenticated
                        ? [
                              {
                                  group: "メインメニュー",
                                  items: [
                                      {
                                          href: "/mypage",
                                          text: "マイページ",
                                      },
                                      {
                                          href: "/users",
                                          text: "ユーザー",
                                      },
                                  ],
                              },
                          ]
                        : []
                }
                renderInternalLink={({ href, children }) => {
                    return <NavLink to={href}>{children}</NavLink>
                }}
            >
                <div
                    css={css`
                        max-width: 100%;
                        padding: 0px 16px 56px;
                    `}
                >
                    <Routes>
                        <Route path={"/mypage"} element={<MyPage />} />
                        <Route path={"/users"} element={<ListUsers />} />
                        <Route
                            path={"/users/:id"}
                            element={<UserDetailPage />}
                        />
                        <Route
                            path={ActivationForm.path}
                            element={<ActivationForm />}
                        />
                        <Route
                            path={ActivationFinished.path}
                            element={<ActivationFinished />}
                        />
                        <Route
                            path={ResetPasswordForm.path}
                            element={<ResetPasswordForm />}
                        />
                        <Route
                            path={ResetPasswordFinished.path}
                            element={<ResetPasswordFinished />}
                        />
                        <Route
                            path={ResetPasswordRequestForm.path}
                            element={<ResetPasswordRequestForm />}
                        />
                        <Route
                            path={ResetPasswordRequestFinished.path}
                            element={<ResetPasswordRequestFinished />}
                        />
                        <Route
                            path={"/companies/new"}
                            element={<NewCompanyPage />}
                        />
                        <Route path={"/companies"} element={<CompanyPage />} />
                        <Route path={"/login"} element={<Login />} />
                        <Route path={"/mfa_login"} element={<MFALogin />} />
                        <Route
                            path={"*"}
                            element={<Navigate to="/mypage" replace />}
                        />
                    </Routes>
                </div>
            </GlobalMenu>
        </Router>
    )
}

const FeedbackProviderWithAuth = ({ children }: React.PropsWithChildren) => {
    const { token } = useAuth()
    const userId = parseJWT<{ userId: string }>(token)?.["userId"]
    return (
        <FeedbackProvider serviceName="m2m-users" userId={userId}>
            {children}
        </FeedbackProvider>
    )
}

export const App = () => {
    return (
        <M2mAuthenticationProvider>
            <AlertProvider>
                <ModalProvider>
                    <FeedbackProviderWithAuth>
                        <AppContent />
                    </FeedbackProviderWithAuth>
                </ModalProvider>
            </AlertProvider>
        </M2mAuthenticationProvider>
    )
}
