import { Button, TextField, Typography, useAlert } from "matsuri-ui"
import { PageHeader } from "../components/PageHeader/PageHeader"
import { StringTypes, genFieldNames } from "../helpers/fieldTypes"
import { UserAuthorityChips } from "../parts/UserAuthorityChips"
import { displayAuthority, requestUpdateUser, useUser } from "../hooks/useUsers"
import { getUser } from "../helpers/getUser"
import { useAuthGuardCtx } from "../hooks/useAuthGuard"
import { useCallback, useMemo } from "react"
import { css } from "@emotion/react"

const fieldNames = genFieldNames(["name"])
type FieldTypes = StringTypes<typeof fieldNames>

export const MyPage: React.FC = () => {
    const { token } = useAuthGuardCtx()
    const authUser = useMemo(() => getUser(token), [token])
    const { data: user, refetch } = useUser(token, authUser?.userId)
    const { addAlert } = useAlert()

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (!user) return

            const formData = new FormData(event.currentTarget)
            const input = Object.fromEntries(formData) as FieldTypes
            const { error } = await requestUpdateUser(token, user.id, {
                name: input.name,
            })

            if (error) {
                addAlert(`エラーが発生しました: ${error}`, {
                    severity: "error",
                })
            } else {
                addAlert(`ユーザー情報を更新しました。`, {
                    severity: "success",
                    duration: 3500,
                })
            }

            refetch()
        },
        [addAlert, refetch, token, user]
    )

    return (
        <div
            css={css`
                margin-bottom: 72px;
            `}
        >
            <PageHeader>
                <Typography variant="h2">あなたのアカウント情報</Typography>
            </PageHeader>

            {user && (
                <form
                    onSubmit={handleSubmit}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 48px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 24px;
                        `}
                    >
                        <UserAuthorityChips user={user} />

                        <TextField label="ID" defaultValue={user.id} readOnly />

                        <TextField
                            label="メールアドレス"
                            defaultValue={user.email}
                            readOnly
                        />

                        <TextField
                            label="所属会社ID"
                            defaultValue={user.companyId}
                            readOnly
                        />

                        <TextField
                            label="名前"
                            name={fieldNames.name}
                            defaultValue={user.name}
                        />

                        <TextField
                            label="権限"
                            defaultValue={displayAuthority(user.authority)}
                            readOnly
                        />

                        <TextField
                            label="許可されたスコープ"
                            defaultValue={JSON.stringify(user.scope)}
                            readOnly
                        />
                    </div>

                    <Button
                        variant="filled"
                        color="primary"
                        css={css`
                            align-self: flex-start;
                        `}
                    >
                        更新する
                    </Button>
                </form>
            )}
        </div>
    )
}
