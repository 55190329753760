import { Button, TextField, Typography, useAlert } from "matsuri-ui"
import { FormEvent, useState } from "react"
import { StringTypes, genFieldNames } from "../helpers/fieldTypes"
import { css } from "@emotion/react"
import { randomString } from "../helpers/random"
import { requestCreateCompany } from "../hooks/useCompany"
import { useAuth } from "m2m-components/storage/useM2mAuth"
import { useNavigate } from "react-router-dom"
import { ScopeSelectField } from "../parts/ScopeModal"
import { UserScope } from "../hooks/useUsers"

const fieldNames = genFieldNames([
    "companyName",
    "userName",
    "email",
    "authority",
])
type FieldTypes = StringTypes<typeof fieldNames>

export const NewCompanyPage = () => {
    const { token } = useAuth()
    const { addAlert } = useAlert()
    const navigate = useNavigate()

    const [scope, setScope] = useState<UserScope>({})

    return (
        <>
            <header
                css={css`
                    margin: 16px 0;
                `}
            >
                <Typography variant="h2">新規会社を登録</Typography>
            </header>

            <form
                css={css`
                    display: grid;
                    gap: 8px;
                    max-width: 550px;
                `}
                onSubmit={async (event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault()

                    const input = Object.fromEntries(
                        new FormData(event.currentTarget)
                    ) as FieldTypes

                    const { data, error } = await requestCreateCompany(token, {
                        name: input.companyName,
                        // 初回パスワードはユーザーに通知する必要がないので適当なものを生成
                        password: randomString(20),
                        email: input.email,
                        userName: input.userName,
                        userEmail: input.email,
                        userAuthority: input.authority,
                        scope,
                    })
                    if (error) {
                        addAlert(`会社の登録に失敗しました: ${error}`, {
                            severity: "error",
                        })

                        return
                    } else {
                        addAlert(
                            `会社を登録しました。管理者にはメールが送られるのでそちらからアカウントの有効化と、パスワードの再設定を案内してください。`,
                            {
                                severity: "success",
                                duration: 3500,
                            }
                        )

                        // IDなどを表示するためのやつ
                        // 会社閲覧ページを作ったら破壊して詳細ページへのリダイレクトなどで置き換えるべき
                        addAlert(`response: ${JSON.stringify(data)}`, {
                            severity: "success",
                        })
                    }

                    navigate(`/companies`)
                }}
            >
                <TextField label="会社名" name={fieldNames.companyName} />
                <TextField label="管理者名" name={fieldNames.userName} />
                <TextField
                    label="管理者メールアドレス"
                    name={fieldNames.email}
                    type="email"
                />
                {/* 基本的にマネージャー以外のアカウントを作らせる必要がないのでここはハードコードする */}
                <TextField
                    label="権限(Authority)"
                    name={fieldNames.authority}
                    value="Manager"
                    readOnly
                />
                <ScopeSelectField value={scope} onChange={setScope} />

                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 16px;
                    `}
                >
                    <Button variant="filled" color="primary" type="submit">
                        新規登録
                    </Button>
                </div>
            </form>
        </>
    )
}
