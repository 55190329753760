import { Button, Modal, TextField, Typography } from "matsuri-ui"
import { css } from "@emotion/react"
import { passwordStrength } from "check-password-strength"
import { useResetPassword } from "./useResetPassword"

const sx = {
    root: {
        padding: "1vh 4vw",
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        width: "90%",
    },
    noshrink: {
        flexShrink: 0,
    },
} as const

const displayPasswordStrengthValue = (value: string) => {
    return {
        "Too weak": "弱過ぎるパスワードです",
        Weak: "弱いパスワードです",
        Medium: "中程度のパスワードです",
        Strong: "強いパスワードです",
    }[value]
}
const isStrengthTooWeak = (value: string) => value === "Too weak"
const isStrengthMediumOrStrong = (value: string) =>
    value === "Medium" || value === "Strong"

export const ResetPasswordForm: Page = () => {
    const {
        fetching,
        errorMessage,
        handleSubmit,
        handleClearError,
        newPassword,
        setNewPassword,
    } = useResetPassword()
    const strength = passwordStrength(newPassword).value

    return (
        <>
            <h2>パスワードの入力</h2>
            {errorMessage && (
                <Modal
                    style={sx.modal}
                    body={
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 16px;
                            `}
                        >
                            <Typography
                                color="error"
                                variant="h4"
                                align="center"
                            >
                                ERROR
                            </Typography>
                            <Typography>{errorMessage}</Typography>
                        </div>
                    }
                    footer={
                        <Button
                            variant="filled"
                            color="primary"
                            fullWidth
                            onClick={handleClearError}
                        >
                            OK
                        </Button>
                    }
                    backdrop
                />
            )}

            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                `}
            >
                <TextField
                    onChange={setNewPassword}
                    value={newPassword}
                    type="password"
                    label="新しいパスワードをご入力ください"
                    autoComplete="new-password"
                    minLength={12}
                    helperText={
                        newPassword !== "" ? (
                            <Typography
                                variant="caption"
                                color={
                                    isStrengthMediumOrStrong(strength)
                                        ? undefined
                                        : "error"
                                }
                            >
                                {displayPasswordStrengthValue(strength)}
                            </Typography>
                        ) : undefined
                    }
                />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="filled"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={fetching || isStrengthTooWeak(strength)}
                    >
                        パスワードを更新
                    </Button>
                </div>
            </div>
        </>
    )
}

ResetPasswordForm.path = "/reset_password_form"
