import { useAuth } from "m2m-components/storage/useM2mAuth"

export const getAuthority = (token: string) => {
    const parts = token.split(".")
    if (parts.length !== 3) {
        return null
    }
    const payload = JSON.parse(atob(parts[1]))

    return payload["authority"]
}

export interface AuthPayload {
    loginType: "Normal" | "MFA"
    userId: string
    authority: string
}

export const useAuthPayload = (): Partial<AuthPayload> | undefined => {
    const { token, authenticated } = useAuth()
    if (!authenticated) {
        return undefined
    }

    const parts = token.split(".")
    if (parts.length !== 3) {
        return undefined
    }
    const payload = JSON.parse(atob(parts[1]))

    return payload
}
